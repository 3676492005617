// order types
export const RECURRING = 'Récurrente';
export const PUNCTUAL = 'Ponctuelle';

// previous orderIds
export const FIRST_ORDER = 'firstOrder';
export const PUNCTUAL_ORDER = 'punctualOrder';

// bad service labels
export function getServiceLabel(service: string) {
    switch (service) {
        case 'delivery':
            return 'Livraison';
        case 'equipments':
            return 'Équipements';
        case 'products':
            return 'Qualité des produits';
        case 'quantity':
            return 'Quantité';
        case 'productsMissing':
            return 'Produits manquants';
        case 'webapp':
            return 'Espace client';
        default:
            return '';
    }
}
