import React from 'react';
import { useMutation } from '@apollo/client';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import { DeliveryState } from 'data/__generated__';
import { CHARGE_ORDER, CHARGE_ORDERVariables } from 'data/mutations/__generated__/CHARGE_ORDER';
import { CHARGE_ORDER_MUTATION } from 'data/mutations/order';
import { GET_DETAILED_ORDER_detailedOrder } from 'data/queries/__generated__/GET_DETAILED_ORDER';

import { FIRST_ORDER, getServiceLabel, PUNCTUAL, PUNCTUAL_ORDER, RECURRING } from 'pages/Orders/constants';
import { DAYS_OF_THE_WEEK } from 'constants/dateAndTime';

import { SectionColumn, SectionContainer, ColumnsSectionContainer } from 'components/DetailsView/Section';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { DetailFormCheckbox, DetailFormDatePicker, DetailLink, DetailValue } from 'components/DetailsView';
import { CopyValue } from 'components/CopyValue';
import { StatusTag } from 'pages/Orders/components/StatusTag';

import { dateFromString, formatDateAsAnniversary } from 'helpers/dateTimes';

export const GeneralInfoSection = ({ order }: { order: GET_DETAILED_ORDER_detailedOrder }) => {
    const {
        _id: orderId,
        appreciation,
        badService,
        delivery,
        deliveryDate,
        deliveryTimeWindows,
        feedback,
        hasOnsiteSetup,
        isPunctual,
        number,
        organization: { _id: organizationId, hasSubscription, name: organizationName },
        paymentId,
        previous_orderId: previousOrderId,
        state,
        totem: { name: totemName, number: totemNumber },
        user: {
            _id: userId,
            profile: { firstname, lastname },
        },
    } = order;

    const [chargeOrder, { loading: chargeOrderLoading }] = useMutation<CHARGE_ORDER, CHARGE_ORDERVariables>(
        CHARGE_ORDER_MUTATION,
        {
            variables: { orderId },
            onCompleted: (data) => {
                if (data.chargeOrder?.paymentId) {
                    toast.success('Commande facturée !');
                } else {
                    toast.error("Une erreur est survenue, la commande n'a pas pu être facturée.");
                }
            },
        },
    );

    const { control } = useFormContext();
    const watchDeliveryDate: string = useWatch({
        control,
        name: 'deliveryDate',
        defaultValue: deliveryDate,
    });
    const today = new Date();

    const getTranslatedReasons = () => {
        const translatedReasons = badService?.map((service: string) => getServiceLabel(service)) || [];
        return translatedReasons.join(', ');
    };

    const dayNumber = dateFromString(watchDeliveryDate).getDay();
    const index = dayNumber === 0 ? 6 : dayNumber - 1;
    const translatedWeekday = DAYS_OF_THE_WEEK[index]?.label || '';

    const hasBeenDelivered = delivery?.stateHistory.some(
        (stateHistoryItem) => stateHistoryItem.state === DeliveryState.Delivered,
    );

    return (
        <SectionContainer title="Informations générales" isInitiallyOpen>
            <ColumnsSectionContainer numberOfColumns={4}>
                <SectionColumn>
                    <CopyValue value={orderId} />
                    <DetailValue label="N° de commande" value={number} />
                    <DetailValue label="Type" value={isPunctual ? PUNCTUAL : RECURRING} />
                    {previousOrderId && ![FIRST_ORDER, PUNCTUAL_ORDER].includes(previousOrderId) ? (
                        <DetailLink label="Id de commande précédente" path="order" value={previousOrderId} />
                    ) : (
                        <DetailValue label="Id de commande précédente" value="-" />
                    )}
                    {delivery?._id ? (
                        <DetailLink label="Id de livraison" path="delivery" value={delivery?._id} />
                    ) : (
                        <DetailValue label="Id de livraison" value="Pas de livraison associée" />
                    )}
                    {hasBeenDelivered && !paymentId ? (
                        <TotemPrimaryButton type="button" disabled={chargeOrderLoading} onClick={() => chargeOrder()}>
                            Facturer la commande
                        </TotemPrimaryButton>
                    ) : paymentId ? (
                        <DetailLink label="Id de paiement" path="payment" value={paymentId} />
                    ) : (
                        <DetailValue label="Id de paiement" value="Pas de paiement associé" />
                    )}
                </SectionColumn>
                <SectionColumn>
                    <DetailLink label="Entreprise" path="organization" name={organizationName} value={organizationId} />
                    <DetailValue label="Totem" value={`Totem N° ${totemNumber} ${totemName ? `- ${totemName}` : ''}`} />
                    <DetailLink label="Admin" path="user" name={`${firstname} ${lastname}`} value={userId} />
                    <StatusTag state={state} showHeading />
                </SectionColumn>
                <SectionColumn>
                    <DetailFormDatePicker
                        label="Date de livraison"
                        placeholder="Date de commande"
                        defaultValue={deliveryDate}
                        name="deliveryDate"
                        control={control}
                        transformValueToDate={(value) => (value ? dateFromString(value) : null)}
                        transformDateToValue={(date) => (date ? formatDateAsAnniversary({ dateTime: date }) : null)}
                        isDisabled
                        minDate={today}
                    />
                    <DetailValue label="Jour de la semaine" value={translatedWeekday} />
                    <DetailValue
                        label="Créneau de livraison"
                        value={`${deliveryTimeWindows[0]?.start ?? '?'} - ${deliveryTimeWindows[0]?.stop ?? '?'}`}
                    />
                    <DetailValue
                        label="Frais de livraison"
                        sublabel="Frais de livraison de base"
                        value={`${hasSubscription ? 0 : 10} €`}
                    />
                    <DetailFormCheckbox
                        label="Mise en place"
                        sublabel={hasSubscription ? '' : '+10 € de frais de livraison'}
                        name="hasOnsiteSetup"
                        defaultChecked={hasOnsiteSetup}
                        disabled={!!delivery}
                    />
                </SectionColumn>
                <SectionColumn>
                    <DetailValue label="Note" value={appreciation ? `${Number(appreciation)}/5` : '-'} />
                    {!!appreciation && Number(appreciation) < 5 ? (
                        <>
                            <DetailValue label="Raison(s)" value={getTranslatedReasons()} />
                            <DetailValue label="Commentaires" value={feedback} />
                        </>
                    ) : null}
                </SectionColumn>
            </ColumnsSectionContainer>
        </SectionContainer>
    );
};
