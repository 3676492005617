import { GET_MICROSTORE_PRODUCTS_STOCK_INFO_getMicrostoreProductsStockInfoQuery } from 'data/queries/__generated__/GET_MICROSTORE_PRODUCTS_STOCK_INFO';
import {
    MicrostoreColumnGroupWithColumnsFragmentFragment,
    MicrostoreColumnUpdateInput,
    MicrostoreColumnWithShelvesFragmentFragment,
} from 'data/__generated__';

export type MicrostoreLocationExpiryDateFormValues = Omit<
    MicrostoreColumnWithShelvesFragmentFragment['shelves'][number]['locationTable'][number][number]['expiryDates'][number],
    '__typename'
>;

export type MicrostoreLocationFormValues = Omit<
    MicrostoreColumnWithShelvesFragmentFragment['shelves'][number]['locationTable'][number][number],
    '__typename' | 'expiryDates'
> & {
    expiryDates: MicrostoreLocationExpiryDateFormValues[];
};

export type MicrostoreShelfFormValues = Omit<
    MicrostoreColumnWithShelvesFragmentFragment['shelves'][number],
    'locationTable'
> & {
    locationTable: MicrostoreLocationFormValues[][];
};

export type MicrostoreColumnFormValues = Omit<MicrostoreColumnWithShelvesFragmentFragment, 'shelves'> & {
    shelves: MicrostoreShelfFormValues[];
};

export type MicrostoreColumnGroupFormValues = Omit<MicrostoreColumnGroupWithColumnsFragmentFragment, 'columns'> & {
    columns: MicrostoreColumnFormValues[];
};

// Querie values to form values
export function mapMicrostoreColumnGroupToMicrostoreColumnGroupFormValues({
    microstoreColumnGroup,
}: {
    microstoreColumnGroup: MicrostoreColumnGroupWithColumnsFragmentFragment;
}): MicrostoreColumnGroupFormValues {
    return {
        ...microstoreColumnGroup,
    };
}

// Form values to input values
export function mapMicrostoreColumnFormValuesToMicrostoreColumnUpdateInput({
    microstoreColumnFormValues,
}: {
    microstoreColumnFormValues: MicrostoreColumnFormValues;
}): MicrostoreColumnUpdateInput {
    return {
        _id: microstoreColumnFormValues._id,
        name: microstoreColumnFormValues.name,
        nayaxMachineId: microstoreColumnFormValues.nayaxMachineId,
        positionInColumnGroup: microstoreColumnFormValues.positionInColumnGroup,
        senseiColumnId: microstoreColumnFormValues.senseiColumnId || null,
        shelves: microstoreColumnFormValues.shelves.map((shelf) => ({
            _id: shelf._id,
            locationTable: shelf.locationTable.map((locationRow) =>
                locationRow.map(({ _id, expiryDates, productId, replacementProductId, stockToAdd }) => ({
                    _id,
                    expiryDates: expiryDates.map((expiryDate) => ({ ...expiryDate, __typename: undefined })),
                    productId,
                    replacementProductId,
                    stockToAdd,
                })),
            ),
        })),
    };
}

export type MicrostoreProductsStockInfoFormValues =
    (GET_MICROSTORE_PRODUCTS_STOCK_INFO_getMicrostoreProductsStockInfoQuery & {
        stockUsedInMicrostore: number;
        isPresentTomorrow: boolean;
    })[];

export function mapMicrostoreProductsStockInfoQueryToFormValues({
    microstoreColumnGroupFormValuesArray,
    microstoreProductsStockInfoQueryData,
}: {
    microstoreColumnGroupFormValuesArray: MicrostoreColumnGroupFormValues[];
    microstoreProductsStockInfoQueryData:
        | GET_MICROSTORE_PRODUCTS_STOCK_INFO_getMicrostoreProductsStockInfoQuery[]
        | undefined;
}): MicrostoreProductsStockInfoFormValues | null {
    const productsQuantitiesInStore = microstoreColumnGroupFormValuesArray.reduce<Record<string, number>>(
        (productsQuantitiesAcc, { columns }) => {
            return columns.reduce<Record<string, number>>(
                (productsQuantitiesColumnGroupAcc, { shelves }) => {
                    return shelves.reduce<Record<string, number>>(
                        (productsQuantitiesColumnAcc, { locationTable }) => {
                            return locationTable.reduce<Record<string, number>>(
                                (productsQuantitiesShelfAcc, locationRow) => {
                                    return locationRow.reduce<Record<string, number>>(
                                        (productsQuantitiesLocationRowAcc, { replacementProductId, stockToAdd }) => {
                                            const newProductsQuantities = { ...productsQuantitiesLocationRowAcc };
                                            if (!replacementProductId) {
                                                return newProductsQuantities;
                                            }
                                            if (!newProductsQuantities[replacementProductId]) {
                                                newProductsQuantities[replacementProductId] = 0;
                                            }
                                            newProductsQuantities[replacementProductId] += stockToAdd;

                                            return newProductsQuantities;
                                        },
                                        { ...productsQuantitiesShelfAcc },
                                    );
                                },
                                { ...productsQuantitiesColumnAcc },
                            );
                        },
                        { ...productsQuantitiesColumnGroupAcc },
                    );
                },
                { ...productsQuantitiesAcc },
            );
        },
        {},
    );
    return microstoreProductsStockInfoQueryData
        ? microstoreProductsStockInfoQueryData.map((productStockInfo) => {
              return {
                  ...productStockInfo,
                  stockUsedInMicrostore: productsQuantitiesInStore[productStockInfo._id] ?? 0,
                  isPresentTomorrow: productsQuantitiesInStore[productStockInfo._id] !== undefined,
              };
          })
        : null;
}

export function microstoreColumnSomeLocationHelper({
    microstoreColumnFormValues,
    someFunction,
}: {
    microstoreColumnFormValues: MicrostoreColumnFormValues;
    someFunction: (location: MicrostoreLocationFormValues) => boolean;
}): boolean {
    return microstoreColumnFormValues.shelves.some((shelf) =>
        shelf.locationTable.some((locationRow) => locationRow.some((location) => someFunction(location))),
    );
}
