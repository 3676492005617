import React from 'react';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';

import { GET_ORGANIZATIONS } from 'data/queries/__generated__/GET_ORGANIZATIONS';
import { GET_ORGANIZATIONS_QUERY } from 'data/queries/organization';

import { PageTitle } from 'components/PageTitle';
import { Header, HeaderTitle } from 'components/Header';
import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { StatusTag } from './components/StatusTag';
import { OrganizationState } from 'data/__generated__';
import { TotemInput } from 'components/TotemInput';
import { PaymentType } from './components/PaymentType';

type OrganizationTable = {
    _id: string;
    name: string;
    state: JSX.Element;
    paymentType: JSX.Element;
};

export const Organizations = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedStates = searchParams.getAll('state');
    const filterString = searchParams.get('filterString') || '';

    const {
        loading: organizationsLoading,
        error: organizationsError,
        data: organizationsData,
    } = useQuery<GET_ORGANIZATIONS>(GET_ORGANIZATIONS_QUERY);

    const ORGANIZATION_FIELDS_TO_DISPLAY: FieldToDisplay<OrganizationTable>[] = [
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: 'paymentType',
            label: 'Type de paiement',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
    ];

    if (organizationsLoading && !organizationsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (organizationsError || !organizationsData) {
        toast.error('Une erreur est survenue lors de la récupération des organisations');
        return null;
    }

    const { organizations } = organizationsData;

    const filteredOrganizations = organizations.filter((organization) => {
        if (filterString.length > 1) {
            return organization.name
                .toLowerCase()
                .replace(' ', '')
                .includes(filterString.toLowerCase().replace(' ', ''));
        }

        return !selectedStates.length || selectedStates.includes(organization.state);
    });

    const formattedOrganizations: OrganizationTable[] = filteredOrganizations.map(
        ({ _id, name, paymentType, state }) => ({
            _id,
            name,
            state: <StatusTag state={state} />,
            paymentType: <PaymentType paymentType={paymentType} />,
        }),
    );

    function toggleStateSelection(state: OrganizationState) {
        return () => {
            if (selectedStates.includes(state)) {
                // @ts-ignore TS2554 (your editor won't show an error, but react-scripts will)
                searchParams.delete('state', state);
                setSearchParams(searchParams);
            } else {
                searchParams.append('state', state);
                setSearchParams(searchParams);
            }
        };
    }

    function updateSearchParams(value: string): void {
        if (value === '') {
            searchParams.delete('filterString');
        } else {
            searchParams.set('filterString', value);
        }
        setSearchParams(searchParams);
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.organizations} />
                </HeaderTitle>
            </Header>
            <Content>
                <TotemInput
                    label="Recherche"
                    onChange={updateSearchParams}
                    placeholder="Nom de l'organisation"
                    value={filterString}
                />
                <Filters>
                    <Title>Filtres</Title>
                    {(Object.keys(OrganizationState) as OrganizationState[]).map((state) => (
                        <FilterContainer
                            key={state}
                            isSelected={selectedStates.includes(state)}
                            onClick={toggleStateSelection(state)}
                        >
                            <StatusTag state={state} />
                        </FilterContainer>
                    ))}
                </Filters>
                <OrganizationsList>
                    {formattedOrganizations.length ? (
                        <ListView<OrganizationTable>
                            fieldsToDisplay={ORGANIZATION_FIELDS_TO_DISPLAY}
                            items={formattedOrganizations}
                            linkBasePath="/organization/"
                            keyExtractor={(item) => item._id}
                        />
                    ) : (
                        "Aucune organisation n'a été trouvée"
                    )}
                </OrganizationsList>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`;

const FilterContainer = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border-radius: 20px;
    border: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.darkBorderColor : 'transparent')};
`;

const OrganizationsList = styled.div`
    flex: 1;
    overflow-y: scroll;
`;
