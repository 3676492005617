import gql from 'graphql-tag';

import { SITE_FRAGMENT } from '../fragments/site';

export const CREATE_SITE_MUTATION = gql`
    mutation CREATE_SITE(
        $accessTime: Int!
        $deliveryDays: [Boolean!]!
        $deliveryWindowStart: String!
        $deliveryWindowStop: String!
        $estimatedUserCount: Int!
        $launchDate: String
        $name: String!
        $organizationId: ID!
        $reassortmentStockTargetRatios: ReassortmentStockTargetRatiosInput!
        $reassortmentStockTargets: ReassortmentStockByCategoriesInput!
        $reassortmentOnboardingStockTargets: ReassortmentStockByCategoriesInput!
        $totemId: ID!
        $type: String!
        $state: String!
    ) {
        createSite(
            accessTime: $accessTime
            deliveryDays: $deliveryDays
            deliveryWindowStart: $deliveryWindowStart
            deliveryWindowStop: $deliveryWindowStop
            estimatedUserCount: $estimatedUserCount
            launchDate: $launchDate
            name: $name
            organizationId: $organizationId
            reassortmentStockTargetRatios: $reassortmentStockTargetRatios
            reassortmentStockTargets: $reassortmentStockTargets
            reassortmentOnboardingStockTargets: $reassortmentOnboardingStockTargets
            totemId: $totemId
            type: $type
            state: $state
        ) {
            success
            error
            site {
                _id
                name
            }
        }
    }
`;

export const UPDATE_SITE_MUTATION = gql`
    mutation UPDATE_SITE(
        $accessTime: Int!
        $removalDate: String
        $B2CPriceRange: ID
        $unknownShrinkageBillingThreshold: Int
        $closingDatesRanges: [SiteClosingDatesRangeInput!]!
        $deliveryDays: [Boolean!]!
        $deliveryWindowStart: String!
        $deliveryWindowStop: String!
        $estimatedUserCount: Int!
        $hasExternalSetupProcess: Boolean!
        $isSecured: Boolean!
        $hasCoffee: Boolean!
        $launchDate: String
        $name: String!
        $reassortmentStockTargetRatios: ReassortmentStockTargetRatiosInput!
        $reassortmentStockTargets: ReassortmentStockByCategoriesInput!
        $reassortmentOnboardingStockTargets: ReassortmentStockByCategoriesInput!
        $shouldAutomaticallyCreateTargetReassortment: Boolean!
        $shouldAutomaticallyCreateManualReassortment: Boolean!
        $shouldHaveTheftNotice: Boolean!
        $shouldModulateForExpired: Boolean!
        $shouldModulateForUnknownLosses: Boolean!
        $siteId: ID!
        $state: String!
        $unwantedProductIds: [String!]!
        $urbantzMetadata: SiteMetadataInput!
        $useNayaxCardForCoffee: Boolean!
        $wantedProductIds: [String!]!
    ) {
        updateSite(
            accessTime: $accessTime
            removalDate: $removalDate
            B2CPriceRange: $B2CPriceRange
            unknownShrinkageBillingThreshold: $unknownShrinkageBillingThreshold
            closingDatesRanges: $closingDatesRanges
            deliveryDays: $deliveryDays
            deliveryWindowStart: $deliveryWindowStart
            deliveryWindowStop: $deliveryWindowStop
            estimatedUserCount: $estimatedUserCount
            hasExternalSetupProcess: $hasExternalSetupProcess
            isSecured: $isSecured
            hasCoffee: $hasCoffee
            launchDate: $launchDate
            name: $name
            reassortmentStockTargetRatios: $reassortmentStockTargetRatios
            reassortmentStockTargets: $reassortmentStockTargets
            reassortmentOnboardingStockTargets: $reassortmentOnboardingStockTargets
            shouldAutomaticallyCreateTargetReassortment: $shouldAutomaticallyCreateTargetReassortment
            shouldAutomaticallyCreateManualReassortment: $shouldAutomaticallyCreateManualReassortment
            shouldHaveTheftNotice: $shouldHaveTheftNotice
            shouldModulateForExpired: $shouldModulateForExpired
            shouldModulateForUnknownLosses: $shouldModulateForUnknownLosses
            siteId: $siteId
            state: $state
            unwantedProductIds: $unwantedProductIds
            urbantzMetadata: $urbantzMetadata
            useNayaxCardForCoffee: $useNayaxCardForCoffee
            wantedProductIds: $wantedProductIds
        ) {
            success
            error
            site {
                ...SiteFragment
            }
        }
    }
    ${SITE_FRAGMENT}
`;

export const UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS_MUTATION = gql`
    mutation UPDATE_SITE_REASSORTMENT_QUANTITY_TARGETS(
        $siteId: ID!
        $reassortmentStockTargets: ReassortmentStockByCategoriesInput!
        $reassortmentOnboardingStockTargets: ReassortmentStockByCategoriesInput!
    ) {
        updateSiteReassortmentStockTargets(
            siteId: $siteId
            reassortmentStockTargets: $reassortmentStockTargets
            reassortmentOnboardingStockTargets: $reassortmentOnboardingStockTargets
        ) {
            success
            error
            site {
                ...SiteFragment
            }
        }
    }
    ${SITE_FRAGMENT}
`;

export const UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS_MUTATION = gql`
    mutation UPDATE_SITE_REASSORTMENT_QUANTITY_TARGET_RATIOS(
        $siteId: ID!
        $reassortmentStockTargetRatios: ReassortmentStockTargetRatiosInput!
    ) {
        updateSiteReassortmentStockTargetRatios(
            siteId: $siteId
            reassortmentStockTargetRatios: $reassortmentStockTargetRatios
        ) {
            success
            error
            site {
                ...SiteFragment
            }
        }
    }
    ${SITE_FRAGMENT}
`;

export const DECLARE_SITE_EXTERNAL_INVENTORY_MUTATION = gql`
    mutation DECLARE_SITE_EXTERNAL_INVENTORY($productsInventoryInfo: [SiteExternalInventoryProduct!]!, $siteId: ID!) {
        declareSiteExternalInventory(productsInventoryInfo: $productsInventoryInfo, siteId: $siteId) {
            _id
        }
    }
`;
