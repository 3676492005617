import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { ColumnsSectionContainer, SectionColumn } from 'components/DetailsView/Section';

import { GET_ALL_PRODUCTS_QUERY } from 'data/queries/product';
import { GET_ALL_PRODUCTS } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { Loader } from 'components/Loader';
import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { FaTrash } from 'react-icons/fa';
import { colors } from 'constants/colors';
import { UpdatedSiteType } from '../SiteDetails';

export const SiteWantedProducts = ({
    siteDetails,
    setUpdatedSiteDetails,
}: {
    siteDetails: UpdatedSiteType;
    setUpdatedSiteDetails: (site: UpdatedSiteType) => void;
}) => {
    const {
        data: productsData,
        loading: productsLoading,
        error: productsError,
    } = useQuery<GET_ALL_PRODUCTS>(GET_ALL_PRODUCTS_QUERY);

    if (productsLoading && !productsData) {
        return (
            <ColumnsSectionContainer numberOfColumns={1}>
                <Loader />
            </ColumnsSectionContainer>
        );
    }

    if (productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des produits du fournisseur');
    }

    const { getAllProducts: products } = productsData;

    function addWantedProduct(productId: string) {
        if (siteDetails.wantedProductIds.some((wantedProductId) => wantedProductId === productId)) {
            toast.info('Le produit est déjà dans la liste');
            return;
        }

        setUpdatedSiteDetails({ ...siteDetails, wantedProductIds: [...siteDetails.wantedProductIds, productId] });
    }

    function removeWantedProduct(productId: string) {
        const newWantedProductIds = siteDetails.wantedProductIds.filter((wantedProductId) => {
            return wantedProductId !== productId;
        });

        setUpdatedSiteDetails({ ...siteDetails, wantedProductIds: [...newWantedProductIds] });
    }

    function addUnwantedProduct(productId: string) {
        if (siteDetails.unwantedProductIds.some((unwantedProductId) => unwantedProductId === productId)) {
            toast.info('Le produit est déjà dans la liste');
            return;
        }

        setUpdatedSiteDetails({ ...siteDetails, unwantedProductIds: [...siteDetails.unwantedProductIds, productId] });
    }

    function removeUnwantedProduct(productId: string) {
        const newUnwantedProductIds = siteDetails.unwantedProductIds.filter((unwantedProductId) => {
            return unwantedProductId !== productId;
        });

        setUpdatedSiteDetails({ ...siteDetails, unwantedProductIds: [...newUnwantedProductIds] });
    }

    const productsOptions =
        products
            .filter(({ _id }) => !siteDetails.wantedProductIds.some((wantedProductId) => _id === wantedProductId))
            .map(({ _id, fullname }) => ({
                value: _id,
                label: fullname,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    return (
        <ColumnsSectionContainer numberOfColumns={2}>
            <SectionColumn>
                <Title>Produit voulu sur site</Title>
                <TotemSelect
                    placeholder="Sélectionner un produit"
                    options={productsOptions}
                    onChange={(option: SelectedOption<string>) => {
                        if (option) {
                            addWantedProduct(option.value);
                        }
                    }}
                />
                {siteDetails.wantedProductIds.map((wantedProductId) => {
                    const product = products.find(({ _id }) => wantedProductId === _id);
                    return (
                        <ProductContainer key={wantedProductId}>
                            <ProductName>{product?.fullname ?? wantedProductId}</ProductName>
                            {!product ? (
                                <ErrorMessage>Le produit n'est pas trouvable en base de données</ErrorMessage>
                            ) : null}

                            <TotemPrimaryButton type="button" onClick={() => removeWantedProduct(wantedProductId)}>
                                <FaTrash size={20} />
                            </TotemPrimaryButton>
                        </ProductContainer>
                    );
                })}
            </SectionColumn>
            <SectionColumn>
                <Title>Produit non voulu sur site</Title>
                <TotemSelect
                    placeholder="Sélectionner un produit"
                    options={productsOptions}
                    onChange={(option: SelectedOption<string>) => {
                        if (option) {
                            addUnwantedProduct(option.value);
                        }
                    }}
                />
                {siteDetails.unwantedProductIds.map((unwantedProductId) => {
                    const product = products.find(({ _id }) => unwantedProductId === _id);
                    return (
                        <ProductContainer key={unwantedProductId}>
                            <ProductName>{product?.fullname ?? unwantedProductId}</ProductName>
                            {!product ? (
                                <ErrorMessage>Le produit n'est pas trouvable en base de données</ErrorMessage>
                            ) : null}

                            <TotemPrimaryButton type="button" onClick={() => removeUnwantedProduct(unwantedProductId)}>
                                <FaTrash size={20} />
                            </TotemPrimaryButton>
                        </ProductContainer>
                    );
                })}
            </SectionColumn>
        </ColumnsSectionContainer>
    );
};

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
`;
const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`;
const ProductName = styled.div`
    font-weight: bold;
    margin-left: 15px;
    flex-grow: 1;
`;

const ErrorMessage = styled.div`
    color: ${colors.red};
`;
