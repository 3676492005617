import gql from 'graphql-tag';
import { MICROSTORE_COLUMN_WITH_SHELVES_FRAGMENT } from 'data/fragments/microstoreColumn';
import { MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT } from 'data/fragments/microstoreColumnGroup';
import { SITE_FRAGMENT } from '../fragments/site';

export const UPDATE_MICROSTORE_COLUMN_MUTATION = gql`
    mutation UPDATE_MICROSTORE_COLUMN($microstoreColumnUpdateInput: MicrostoreColumnUpdateInput!) {
        updateMicrostoreColumnMutation(microstoreColumnUpdateInput: $microstoreColumnUpdateInput) {
            newMicrostoreColumn {
                ...MicrostoreColumnWithShelvesFragment
            }
            errors
        }
    }
    ${MICROSTORE_COLUMN_WITH_SHELVES_FRAGMENT}
`;

export const CREATE_MICROSTORE_COLUMN_MUTATION = gql`
    mutation CREATE_MICROSTORE_COLUMN($createMicrostoreColumnInput: CreateMicrostoreColumnInput!) {
        createMicrostoreColumnMutation(createMicrostoreColumnInput: $createMicrostoreColumnInput) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const REPLACE_MICROSTORE_COLUMN_MUTATION = gql`
    mutation REPLACE_MICROSTORE_COLUMN($replaceMicrostoreColumnInput: ReplaceMicrostoreColumnInput!) {
        replaceMicrostoreColumnMutation(replaceMicrostoreColumnInput: $replaceMicrostoreColumnInput) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const MOVE_COLUMN_MUTATION = gql`
    mutation MOVE_COLUMN($columnId: String!, $columnGroupId: ID!, $positionInColumnGroup: Int!) {
        moveColumnMutation(
            columnId: $columnId
            columnGroupId: $columnGroupId
            positionInColumnGroup: $positionInColumnGroup
        ) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const UPDATE_COLUMN_INFO_MUTATION = gql`
    mutation UPDATE_COLUMN_INFO($columnId: String!, $isPaused: Boolean!, $removalDate: String, $startingDate: String) {
        updateColumnInfoMutation(
            columnId: $columnId
            isPaused: $isPaused
            removalDate: $removalDate
            startingDate: $startingDate
        ) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const DELETE_MICROSTORE_COLUMN_MUTATION = gql`
    mutation DELETE_MICROSTORE_COLUMN($microstoreColumnId: String!) {
        deleteMicrostoreColumnMutation(microstoreColumnId: $microstoreColumnId) {
            errors
            newSite {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const VALIDATE_MICROSTORES_REASSORTMENT_MUTATION = gql`
    mutation VALIDATE_MICROSTORES_REASSORTMENT($reassortmentFlow: ReassortmentFlow!, $siteIds: [ID!]!) {
        validateMicrostoresReassortmentMutation(reassortmentFlow: $reassortmentFlow, siteIds: $siteIds) {
            errors
            newSites {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
                microstoreReassortmentStockTransfers {
                    _id
                    reassortmentFlow
                    state
                    transferDate
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW_MUTATION = gql`
    mutation UPDATE_MICROSTORES_REASSORTMENT_FOR_FRESH_FLOW(
        $shouldUseNewRestockingVersion: Boolean!
        $siteIds: [ID!]!
    ) {
        updateMicrostoresReassortmentForFreshFlowMutation(
            shouldUseNewRestockingVersion: $shouldUseNewRestockingVersion
            siteIds: $siteIds
        ) {
            errors
            newSites {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
            warnings
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW_MUTATION = gql`
    mutation UPDATE_MICROSTORES_REASSORTMENT_FOR_DRY_FLOW($siteIds: [ID!]!) {
        updateMicrostoresReassortmentForDryFlowMutation(siteIds: $siteIds) {
            errors
            newSites {
                ...SiteFragment
                microstoreColumnGroups {
                    ...MicrostoreColumnGroupWithColumnsFragment
                }
            }
        }
    }
    ${SITE_FRAGMENT}
    ${MICROSTORE_COLUMN_GROUP_WITH_COLUMNS_FRAGMENT}
`;

export const LAUNCH_FRESH_RESTOCKING_MUTATION = gql`
    mutation LAUNCH_FRESH_RESTOCKING {
        launchFreshRestockingMutation {
            success
            errors
        }
    }
`;

export const SYNC_PLANOGRAM_WITH_SENSEI_MUTATION = gql`
    mutation SYNC_PLANOGRAM_WITH_SENSEI($microstoreColumnId: String!, $columnPhotoUrl: String) {
        synchronizePlanogramWithSensei(microstoreColumnId: $microstoreColumnId, columnPhotoUrl: $columnPhotoUrl) {
            success
            errors
        }
    }
`;
